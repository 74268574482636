import React from 'react'
import type { Color } from 'chroma-js'

interface ColorEntryProps {
  color: Color
}

const ColorEntry: React.FC<ColorEntryProps> = ({ color }) => (
  <div
    style={{
      ...style,
      backgroundColor: color.hex(),
    }}
  />
)

const style: React.CSSProperties = {
  width: '32px',
  height: '32px',
  borderRadius: '2px',
}

export default ColorEntry
