import React from 'react'
import ColorEntry from '../ColorEntry'

import type { Color } from 'chroma-js'

interface ColorListProps {
  colors: Color[]
}

const ColorList: React.FC<ColorListProps> = ({ colors }) => {
  return (
    <div style={style}>
      {colors.map((color, index) => (
        <ColorEntry key={index} color={color} />
      ))}
    </div>
  )
}

const style: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
}

export default ColorList
