import React from 'react'
import chroma from 'chroma-js'
import { ChromePicker } from 'react-color'

import type { ColorResult } from 'react-color'
import type { Color } from 'chroma-js'

interface Props {
  color?: Color
  onColorPicked?: (color: Color) => void
}

const ColorEntry: React.FC<Props> = ({ color, onColorPicked }) => {
  const [isPickingColor, setIsPickingColor] = React.useState<boolean>(false)
  const [pickedColor, setPickedColor] = React.useState<string>(
    color?.hex() || chroma.random().hex()
  )

  const handleOnColorClicked = () => {
    setIsPickingColor(true)
  }

  const handleOnCoverClicked = () => {
    setIsPickingColor(false)
  }

  const handleOnColorPicked = (color: ColorResult) => {
    setPickedColor(color.hex)
    onColorPicked?.(chroma.hex(color.hex))
  }
  return (
    <div>
      <div style={styles.ColorEntryContainer}>
        <div
          style={{ ...styles.ColorEntry, backgroundColor: pickedColor }}
          onClick={handleOnColorClicked}
        />
        <div>{color?.hex().toUpperCase()}</div>
      </div>
      {isPickingColor ? (
        <div style={styles.popOver}>
          <div style={styles.cover} onClick={handleOnCoverClicked} />
          <ChromePicker color={pickedColor} onChange={handleOnColorPicked} />
        </div>
      ) : null}
    </div>
  )
}

const styles: Record<string, React.CSSProperties> = {
  ColorEntry: {
    width: '32px',
    height: '32px',
    borderRadius: '2px',
  },
  ColorEntryContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  popOver: {
    position: 'absolute',
    marginTop: '2px',
    zIndex: 100,
  },
  cover: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}

export default ColorEntry
