import React from 'react'
import chroma from 'chroma-js'
import './App.css'
import ColorInput from './components/ColorInput'
import colorCombinations from './utils/colorCombinations'

import type { Color } from 'chroma-js'
import ColorList from './components/ColorList'

type ColorPairResult = {
  pair: [Color, Color]
  contrast: number
  distance: number
}

function App() {
  const [selectedColorPair, setSelectedColorPair] =
    React.useState<ColorPairResult | null>(null)
  const [startingColors, setStartingColors] = React.useState<Color[]>([
    chroma.random(),
    chroma.random(),
  ])

  const swatches: Color[][] = startingColors.map((startingColor) =>
    chroma
      .scale([
        startingColor.luminance(0),
        startingColor,
        startingColor.luminance(1),
      ])
      .colors(11, null)
  )

  const allPossibleCombinations = colorCombinations(swatches.flat(), 2).reduce(
    (acc, curr) => {
      const contrast = chroma.contrast(curr[0], curr[1])
      const distance = chroma.distance(curr[0], curr[1])
      acc.push({
        pair: curr as [Color, Color],
        contrast,
        distance,
      })
      return acc
    },
    [] as ColorPairResult[]
  )

  function handleColorChange(index: number) {
    return (color: Color) => {
      setStartingColors([
        ...startingColors.slice(0, index),
        color,
        ...startingColors.slice(index + 1),
      ])
    }
  }
  const allA11yCombinations = allPossibleCombinations.filter(
    ({ contrast }) => contrast > 4.5
  )

  return (
    <div>
      <div>
        <h1>These are your base colors:</h1>
        <h4>
          Based on these, we generate swatches by tweaking their luminance
        </h4>
      </div>
      <div className="App">
        {startingColors.map((color, index) => (
          <div key={index}>
            <ColorInput
              color={color}
              onColorPicked={handleColorChange(index)}
            />
            <ColorList colors={swatches[index]} />
          </div>
        ))}
        {startingColors.length < 4 && (
          <div>
            <button
              className="AddBtn"
              onClick={() =>
                setStartingColors([...startingColors, chroma.random()])
              }
            >
              Add another base color
            </button>
          </div>
        )}
      </div>
      <div className="Combinations">
        <div className="Pairs">
          <div>
            <h1>
              There are {allA11yCombinations.length} AAA valid color pairs
            </h1>
          </div>
          {allA11yCombinations
            .sort((a, b) => a.distance - b.distance)
            .map(({ pair, contrast, distance }, idx) => (
              <div
                onClick={() =>
                  setSelectedColorPair({ pair, contrast, distance })
                }
                style={{ display: 'flex', flexDirection: 'row' }}
                key={idx}
              >
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: pair[0].hex(),
                  }}
                />
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: pair[1].hex(),
                  }}
                />
              </div>
            ))}
        </div>
        <div className="Preview">
          {selectedColorPair ? (
            <React.Fragment>
              <SampleButton
                backgroundColor={selectedColorPair.pair[0]}
                foregroundColor={selectedColorPair.pair[1]}
              />
              <SampleButton
                backgroundColor={selectedColorPair.pair[1]}
                foregroundColor={selectedColorPair.pair[0]}
              />
            </React.Fragment>
          ) : (
            <div>
              <h1 style={{ fontSize: '3rem' }}>
                Select a pair on the left to see the preview here...
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
interface SampleButtonProps {
  backgroundColor: Color
  foregroundColor: Color
}
const SampleButton: React.FC<SampleButtonProps> = ({
  backgroundColor,
  foregroundColor,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '200px',
        height: '100px',
        backgroundColor: backgroundColor.hex(),
        color: foregroundColor.hex(),
      }}
    >
      <div style={{ fontSize: '2rem' }}>
        {foregroundColor.hex().toUpperCase()}
      </div>
    </div>
  )
}

export default App
