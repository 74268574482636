import type { Color } from 'chroma-js'

const colorCombinations = (
  collection: Color[],
  combinationLength: number = 2
): Color[][] => {
  let head,
    tail,
    result = []
  if (combinationLength > collection.length || combinationLength < 1) {
    return []
  }
  if (combinationLength === collection.length) {
    return [collection]
  }
  if (combinationLength === 1) {
    return collection.map((element) => [element])
  }
  for (let i = 0; i < collection.length - combinationLength + 1; i++) {
    head = collection.slice(i, i + 1)
    tail = colorCombinations(collection.slice(i + 1), combinationLength - 1)
    for (let j = 0; j < tail.length; j++) {
      result.push(head.concat(tail[j]))
    }
  }
  return result
}

export default colorCombinations
